// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-careers-companies-js": () => import("./../../../src/pages/careers/companies.js" /* webpackChunkName: "component---src-pages-careers-companies-js" */),
  "component---src-pages-es-careers-companies-js": () => import("./../../../src/pages/es/careers/companies.js" /* webpackChunkName: "component---src-pages-es-careers-companies-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pt-careers-companies-js": () => import("./../../../src/pages/pt/careers/companies.js" /* webpackChunkName: "component---src-pages-pt-careers-companies-js" */),
  "component---src-pages-pt-index-js": () => import("./../../../src/pages/pt/index.js" /* webpackChunkName: "component---src-pages-pt-index-js" */),
  "component---src-templates-insights-landing-js": () => import("./../../../src/templates/insights-landing.js" /* webpackChunkName: "component---src-templates-insights-landing-js" */),
  "component---src-templates-legal-notice-js": () => import("./../../../src/templates/legal-notice.js" /* webpackChunkName: "component---src-templates-legal-notice-js" */),
  "component---src-templates-template-landing-page-builder-js": () => import("./../../../src/templates/template-landing-page-builder.js" /* webpackChunkName: "component---src-templates-template-landing-page-builder-js" */)
}

